import { useMemo } from 'react';
import { useClient } from 'src/ui/shared/hooks/client.hook';

export const useBannerImageUrl = (key: string | undefined, type: 'EventRequest' | 'Event') => {
    const { careerHubBasePath } = useClient();

    return useMemo(() => {
        if (!key) {
            return undefined;
        }

        return `${careerHubBasePath}/services/employers/v1/banners?type=${encodeURIComponent(
            type
        )}&key=${encodeURIComponent(key)}`;
    }, [careerHubBasePath, key, type]);
};
