import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useMemo } from 'react';
import { DateDisplay } from 'src/ui/shared/components/details/DateDisplay';
import { Icon, IconDefinitionAll } from 'src/ui/shared/components/icon/Icon';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';

interface Props {
    icon?: IconDefinitionAll;
    className?: string;
    start: string;
    end: string;
    timeZoneId?: string;
}

// prior to 5.13 this will always be undefined
const useTimeZone = (timeZoneId: string | undefined) => {
    const {
        includes: { timeZones },
    } = useApiConfiguration();

    const timeZone = useMemo(() => {
        return timeZones?.find(x => x.id === timeZoneId);
    }, [timeZoneId, timeZones]);

    // this is a boolean that can also be undefined
    const isInSameTimeZone = useMemo(() => {
        if (!timeZone) {
            return;
        }

        return new Date().getTimezoneOffset() + timeZone.utcOffset === 0;
    }, [timeZone]);

    return { timeZone, isInSameTimeZone };
};

export const EventStartEndDisplay = ({
    icon,
    className,
    start,
    end,
    timeZoneId,
}: Props): React.ReactElement<any, any> | null => {
    const { isInSameTimeZone, timeZone } = useTimeZone(timeZoneId);

    return (
        <div className="mb-3">
            <Icon icon={icon ?? faCalendar} iconClassName={className}>
                <DateDisplay date={start} endDate={end} utcOffset={timeZone?.utcOffset} showTime />
                {isInSameTimeZone === false && <span className="ml-1 small">{timeZone?.name}</span>}
            </Icon>
        </div>
    );
};
