import { addDays, addMinutes, parse, parseISO, startOfDay } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import omit from 'lodash/omit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactCropperElement } from 'react-cropper';
import { EventRequest } from 'src/models/events/event-request.model';
import { datetimelocalFormat } from 'src/ui/shared/components/forms/controls/datepicker/DatePickerLocalControl';
import { EventLocationForm } from 'src/ui/shared/components/forms/controls/event-location/EventLocationControl';
import { dateHelper } from 'src/ui/shared/helpers/date.helper';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { useFormDefault } from 'src/ui/shared/hooks/form-default.hook';
import { useIsCareerHubVersion } from 'src/ui/shared/hooks/is-careerhub-version.hook';
import { BANNER_RATIO } from '../../../shared/components/forms/controls/cropper/CropperDisplay';
import { EventRequestFormData, EventRequestFormProps } from '../components/forms/EventRequestForm';
import { useEventOnCampusValues } from './event-on-campus-values.hook';

export const useEventRequestForm = ({
    eventRequest,
    state,
    onSubmit,
    onSaveDraft,
}: EventRequestFormProps) => {
    const { locations: onCampusLocations } = useEventOnCampusValues();
    const [savingDraftClicked, setSavingDraftClicked] = useState(false);
    const [bannerFileName, setBannerFileName] = useState<string | undefined>(undefined);
    const [isBlobing, setIsBlobing] = useState(false);

    const {
        value: config,
        includes: { campuses, timeZones },
    } = useApiConfiguration();
    const v5_13 = useIsCareerHubVersion(5, 13);

    const defaultLocation: EventLocationForm = useMemo(() => {
        let location: EventLocationForm = {
            select: undefined,
        };
        if (!eventRequest) {
            return location;
        }

        if (eventRequest.onlineLocation?.url) {
            location = {
                select: 'online',
                onlineUrl: eventRequest.onlineLocation.url,
                onlineInstructions: eventRequest.onlineLocation.onlineInstructions,
            };
            return location;
        }

        if (eventRequest.onCampusLocationId) {
            // it is possible that a campus or locaions becomes invalid
            // while an event request is a draft
            const onCampusLocation = onCampusLocations.find(
                x => x.id === eventRequest.onCampusLocationId
            );
            if (!onCampusLocation) {
                return location;
            }

            location = {
                select: 'onCampus',
                onCampusLocationId: onCampusLocation.id,
                onCampusId: onCampusLocation.campusId,
            };
            return location;
        }

        if (eventRequest.onCampusRequest) {
            location = {
                select: 'onCampusRequest',
                onCampusRequest: eventRequest.onCampusRequest,
            };
            return location;
        }

        if (eventRequest.offCampusLocation?.city) {
            location = {
                select: 'offCampus',
                offCampusCountry: eventRequest.offCampusLocation.country,
                offCampusRegion: eventRequest.offCampusLocation.region,
                offCampusCity: eventRequest.offCampusLocation.city,
                offCampusAddress: eventRequest.offCampusLocation.address,
            };
            return location;
        }

        // should never be hit
        return location;
    }, [eventRequest, onCampusLocations]);

    const getUtcOffset = useCallback(
        (timeZoneId: string | undefined) => {
            const timeZone = timeZones?.find(x => x.id === timeZoneId);
            // in v5_13 this will always be found
            // prior to this, it always defaulted to the client's browsers timezone
            return timeZone?.utcOffset || -new Date().getTimezoneOffset();
        },
        [timeZones]
    );

    // convert to a format that is understood by the "datetime-local" input control
    const getZonedDate = useCallback(
        (date: string | undefined, timeZoneId: string | undefined) => {
            if (!date) {
                return undefined;
            }
            const offset = getUtcOffset(timeZoneId);
            const parsed = parseISO(date);
            const zoned = utcToZonedTime(parsed, 'UTC');
            const withMinutes = addMinutes(zoned, offset);
            const formatted = format(withMinutes, datetimelocalFormat);
            return formatted;
        },
        [getUtcOffset]
    );

    const defaultStart = getZonedDate(eventRequest?.start, eventRequest?.timeZoneId);
    const defaultEnd = getZonedDate(eventRequest?.end, eventRequest?.timeZoneId);

    const defaultTimeZoneId = useMemo(() => {
        if (eventRequest?.timeZoneId) {
            return eventRequest?.timeZoneId;
        }

        if (!v5_13) {
            return undefined;
        }

        return config?.settings?.timeZoneId;
    }, [config?.settings?.timeZoneId, eventRequest?.timeZoneId, v5_13]);

    const formMethods = useFormDefault<EventRequestFormData>({
        defaultValues: {
            ...eventRequest,
            location: defaultLocation,
            start: defaultStart,
            end: defaultEnd,
            timeZoneId: defaultTimeZoneId,
        },
        error: state.error,
    });

    const watchLocationSelect = formMethods.watch('location.select');
    const watchCampusId = formMethods.watch('location.onCampusId');
    const watchTimeZoneId = formMethods.watch('timeZoneId');
    const hasCampusId = !!watchCampusId;

    useEffect(() => {
        if (!watchCampusId || !campuses) {
            return;
        }

        const campus = campuses.find(x => x.id === watchCampusId);
        if (!campus) {
            return;
        }

        if (campus.timeZoneId === watchTimeZoneId) {
            return;
        }

        formMethods.setValue('timeZoneId', campus.timeZoneId);
    }, [campuses, formMethods, watchCampusId, watchTimeZoneId]);

    const disableTimeZoneControl = useMemo(() => {
        if (hasCampusId || !watchLocationSelect) {
            return true;
        }
        if (watchLocationSelect === 'onCampus') {
            return true;
        }

        return false;
    }, [hasCampusId, watchLocationSelect]);

    const onSubmitConvertToEventRequest = useCallback(
        (value: EventRequestFormData) => {
            removeEmptyFields(value);

            const toReturn: Partial<EventRequest> = {
                ...(eventRequest || {}),
                ...omit(value, [
                    'location',
                    'bookingSelect',
                    'onlineLocation',
                    'onCampusRequest',
                    'onCampusLocationId',
                    'offCampusLocation',
                ]),
            };

            switch (value.location.select) {
                case 'online': {
                    toReturn.onlineLocation = {
                        url: value.location.onlineUrl!,
                        onlineInstructions: value.location.onlineInstructions!,
                    };
                    toReturn.onCampusLocationId = undefined;
                    toReturn.onCampusRequest = undefined;
                    toReturn.offCampusLocation = undefined;
                    break;
                }
                case 'onCampusRequest': {
                    toReturn.onCampusRequest = value.location.onCampusRequest;
                    toReturn.onCampusLocationId = undefined;
                    toReturn.onlineLocation = undefined;
                    toReturn.offCampusLocation = undefined;
                    break;
                }
                case 'onCampus': {
                    toReturn.onCampusLocationId = value.location.onCampusLocationId;
                    toReturn.onCampusRequest = undefined;
                    toReturn.onlineLocation = undefined;
                    toReturn.offCampusLocation = undefined;
                    break;
                }
                case 'offCampus': {
                    toReturn.offCampusLocation = {
                        country: value.location.offCampusCountry!,
                        region: value.location.offCampusRegion!,
                        city: value.location.offCampusCity!,
                        address: value.location.offCampusAddress!,
                    };
                    toReturn.onCampusRequest = undefined;
                    toReturn.onlineLocation = undefined;
                    toReturn.onCampusLocationId = undefined;
                    break;
                }
            }

            const now = new Date();
            //in v5_13 this will always be found
            const offset = getUtcOffset(toReturn.timeZoneId);
            const dateTimeLocalControlToUtc = (date: string | undefined) => {
                if (!date) {
                    return undefined;
                }

                const parsed = parse(date, datetimelocalFormat, now);
                const zoned = zonedTimeToUtc(parsed, 'UTC');
                const withOffset = addMinutes(zoned, -offset);
                const formatted = dateHelper.formatInUtc(withOffset);

                return formatted;
            };

            toReturn.start = dateTimeLocalControlToUtc(toReturn.start);
            toReturn.end = dateTimeLocalControlToUtc(toReturn.end);

            return toReturn;
        },
        [eventRequest, getUtcOffset]
    );

    // ToDo: this should be a form wide setting, on all forms
    const removeEmptyFields = (data: any) => {
        Object.keys(data).forEach(key => {
            const objectValue = data[key];
            if (objectValue === '') {
                data[key] = null;
            }
        });
    };

    const onSubmitGetBlob = useCallback(async () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        if (!cropper || !bannerFileName) {
            return;
        }

        const canvusData = cropper.getCanvasData();
        // top and left will always be a negative number
        const can2x =
            canvusData.naturalHeight + canvusData.top >= BANNER_RATIO.height * 2 &&
            canvusData.naturalWidth + canvusData.left >= BANNER_RATIO.width * 2;

        const fileName = bannerFileName.split('.').slice(0, -1).join('.') + '.jpeg';
        const croppedCanvas = cropper.getCroppedCanvas({
            imageSmoothingQuality: 'high',
            height: can2x ? BANNER_RATIO.height * 2 : BANNER_RATIO.height,
            width: can2x ? BANNER_RATIO.width * 2 : BANNER_RATIO.width,
        });

        setIsBlobing(true);
        // 0.9 is the compression quality (90%)
        const blob: Blob | null = await new Promise(resolve =>
            croppedCanvas.toBlob(
                innerBlob => {
                    resolve(innerBlob);
                    setIsBlobing(false);
                },
                'image/jpeg',
                0.9
            )
        );

        if (!blob) {
            return;
        }

        return {
            blob,
            fileName,
        };
    }, [bannerFileName]);

    const onSubmitInner = useCallback(
        (value: EventRequestFormData) => {
            const bannerPromise = onSubmitGetBlob();
            const eventRequest = onSubmitConvertToEventRequest(value);
            void bannerPromise.then(banner => {
                onSubmit({
                    banner,
                    eventRequest,
                });
            });
        },
        [onSubmit, onSubmitConvertToEventRequest, onSubmitGetBlob]
    );
    const onSubmitAsDraft = useCallback(() => {
        setSavingDraftClicked(true);
        void formMethods.handleSubmit(value => {
            const bannerPromise = onSubmitGetBlob();
            const eventRequest = onSubmitConvertToEventRequest(value);
            void bannerPromise.then(banner => {
                onSaveDraft({
                    banner,
                    eventRequest,
                });
            });
        })();
    }, [formMethods, onSaveDraft, onSubmitConvertToEventRequest, onSubmitGetBlob]);

    const cropperRef = useRef<ReactCropperElement>(null);

    const { value } = useApiConfiguration();

    const minDate = useMemo(
        () => startOfDay(addDays(new Date(), value?.events?.requestLeadDays || 0)),
        [value?.events?.requestLeadDays]
    );
    const {
        watch,
        setValue,
        formState: { dirtyFields },
    } = formMethods;
    const [start, end] = watch(['start', 'end']);

    useEffect(() => {
        if (!dirtyFields['end']) {
            return;
        }

        if (end < start) {
            setValue('end', start);
        }
    }, [dirtyFields, end, setValue, start]);

    return {
        onSubmit: onSubmitInner,
        onSubmitAsDraft,
        formMethods,
        defaultLocation,
        savingDraftClicked,
        cropperRef,
        minDate,
        startValue: start,
        setBannerFileName,
        isBlobing,
        hasCampusId,
        disableTimeZoneControl,
        defaultTimeZoneId,
    };
};
